import React from 'react'

import TitleArea from '../components/TitleArea'

import titleBackgroundImage from '../scss/img/our-work-title.jpg'

const OurWorkPage = () => (
    <>
        <TitleArea title="Naš Rad" bckgImage={titleBackgroundImage}/>
    </>
)

export default OurWorkPage