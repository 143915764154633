import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import '../scss/gallery_slider.scss'

import galleryImage1 from '../scss/img/gallery-img-1.jpg'
import galleryImage2 from '../scss/img/gallery-img-2.jpg'
import galleryImage3 from '../scss/img/gallery-img-3.jpg'
import galleryImage4 from '../scss/img/gallery-img-4.jpg'
import galleryImage5 from '../scss/img/gallery-img-5.jpg'

const GallerySlider = () => (
    <div className="gallery_slider_wrapper">
        <Swiper 
            className="gallery_slider"
            slidesPerView={5}
            loop={true}
            breakpoints = {
                {
                    0: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1025: {
                        slidesPerView: 5,
                    }
                }
              }
        >
            <SwiperSlide className="gallery_slide">
                <div className="gallery_slide_inner">
                    <img src={galleryImage1} alt="galerija dete"/>
                </div>
            </SwiperSlide>
            <SwiperSlide className="gallery_slide">
                <div className="gallery_slide_inner">
                    <img src={galleryImage2} alt="galerija logoped"/>
                </div>
            </SwiperSlide>
            <SwiperSlide className="gallery_slide">
                <div className="gallery_slide_inner">
                    <img src={galleryImage3} alt="galerija igra"/>
                </div>
            </SwiperSlide>
            <SwiperSlide className="gallery_slide">
                <div className="gallery_slide_inner">
                    <img src={galleryImage4} alt="galerija čitanje"/>
                </div>
            </SwiperSlide>
            <SwiperSlide className="gallery_slide">
                <div className="gallery_slide_inner">
                    <img src={galleryImage5} alt="galerija učenje"/>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
)

export default GallerySlider