import React from 'react'
import { Helmet } from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobileAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faViber, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'

import AppRouter from './routers/AppRouter'

import 'bootstrap/scss/bootstrap-grid.scss';
import './scss/reset.scss'
import './scss/main.scss'
import './scss/flex_grid.scss'
import './scss/common.scss'

library.add(faWhatsapp, faMobileAlt, faEnvelope, faViber, faFacebook, faInstagram, faMapMarkerAlt)

function App() {
  return (
    <div className="App">
        <Helmet>
          <title>Logoped Beograd - MiLogos</title>
        </Helmet>
        <AppRouter></AppRouter>
    </div>
  )
}

export default App