import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TitleWithBackground from '../components/TitleWithBackground'
import ourServicesTitleBackground from '../scss/img/our-services-title.png'
import '../scss/our_services.scss'

import imageServiceOne from '../scss/img/service-one.png'
import imageServiceTwo from '../scss/img/service-two.png'
import imageServiceThree from '../scss/img/service-three.png'
import imageServiceFour from '../scss/img/service-four.png'

const OurServices = () => (
    <div className="our_services_holder">
        <Container>
            <TitleWithBackground titleText="Naše usluge" titleBckImg={ourServicesTitleBackground} alignment="center"/>
            <h5 className="our_services_subtitle subtitle">
                Potražite savete naših stručnjaka, jer rana dijagnostika i adekvatan tretman omogućavaju da se teškoće minimalizuju i eventualno spreče sekundarni poremećaji
            </h5>
            <Row className="our_services">
                <Col md="6" lg="3" className="our_services_item">
                    <div  className="image_with_text">
                        <img src={imageServiceOne} alt="ukrasna slika uz naslov"/>
                        <h4 className="iwt_title">Individualni logopedski tretmani</h4>
                        <p className="iwt_text">
                            Tretman obuhvata primenu raznih metoda i tehnika u cilju otklanjanja govorno-jezičke patologije i poremećaja komunikacije
                        </p>
                    </div>
                </Col>
                <Col md="6" lg="3" className="our_services_item">
                    <div  className="image_with_text">
                        <img src={imageServiceTwo} alt="ukrasna slika uz naslov"/>
                        <h4 className="iwt_title">Reedukacija psihomotorike</h4>
                        <p className="iwt_text">
                            Tretman reedukacije psihomotorike koristi pokret kako bi se podsticao razvoj u svim oblastima dečjeg funkcionisanja
                        </p>
                    </div>
                </Col>
                <Col md="6" lg="3" className="our_services_item">
                    <div  className="image_with_text">
                        <img src={imageServiceThree} alt="ukrasna slika uz naslov"/>
                        <h4 className="iwt_title">Rana stimulacija senzo-motornog razvoja</h4>
                        <p className="iwt_text">
                            Podrazumeva rani i veoma poželjan odgovor na kašnjenje u razvoju kod dece do treće godine života
                            </p>
                    </div>
                </Col>
                <Col md="6" lg="3" className="our_services_item">
                    <div  className="image_with_text">
                        <img src={imageServiceFour} alt="ukrasna slika uz naslov"/>
                        <h4 className="iwt_title">Psihološka testiranja, terapije i savetovanja</h4>
                        <p className="iwt_text">
                            U saradanji sa timom psihologa iz različitih razvojnih oblasti korisnici naših usluga dobijaju sveobuhvatnu procenu razvoja
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
)

export default OurServices