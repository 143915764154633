import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'

import TitleArea from '../components/TitleArea'
import GoogleMap from '../components/GoogleMap'
import ContactForm from '../components/ContactForm'

import titleBackgroundImage from '../scss/img/contact-title.jpg'

const ContactPage = () => (
    <main>
        <TitleArea title="Kontakt" bckgImage={titleBackgroundImage}/>
        <Container>
            <Row className="map_address_holder align-items-center">
                <Col className="google_map_holder_wrapper" md="6">
                    <div className="google_map_holder">
                        <GoogleMap />
                    </div>
                </Col>
                <Col md="6" className="address_holder_wrapper">
                    <div className="address_holder">
                        <h4>Adresa</h4>
                        <div className="address">
                            <p>Bulevar Mihajla Pupina 10/ž</p>
                            <p>11070 Novi Beograd</p>
                        </div>
                        <div>
                            <p className="contact_info mobile">
                                <FontAwesomeIcon className="font_awesome contact_icons" icon="mobile-alt"/>
                                <a href="tel:+381691485558">0691485558</a>
                            </p>
                            <p className="contact_info mail">
                                <FontAwesomeIcon className="font_awesome contact_icons" icon="envelope"/>
                                <a href="mailto:milogosnbgd@gmail.com">milogosnbgd@gmail.com</a>
                            </p>
                        </div>
                        <div className="social_icons_holder">
                            <a href="https://www.facebook.com/logopedbeograd.rs" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'facebook']}/>
                            </a>
                            <a href="https://www.instagram.com/logopedbeograd.rs" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'instagram']}/>
                            </a>
                            <a href="viber://chat/?number=%2B381691485558">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'viber']}/>
                            </a>
                            <a href="https://wa.me/+381691485558">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'whatsapp']}/>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <ContactForm />
    </main>
)

export default ContactPage