import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import TitleWithBackground from './TitleWithBackground'

import '../scss/seek_for_help.scss'
import seekForHelpImg from '../scss/img/seek-for-help.png'
import seekForHelpTitleBackground from '../scss/img/title-background-yellow.png'

const SeekForHelp = () => (
    <div className="seek_for_help">
        <Container>
            <Row>
                <Col xl="6">
                    <div className="sfh_image_holder">
                        <img src={seekForHelpImg} alt="seek_for_help"/>
                    </div>

                </Col>
                <Col xl="6">
                    <div>
                        <TitleWithBackground titleText="Kada se obratiti logopedu?" titleBckImg={seekForHelpTitleBackground} alignment="left"/>
                        <p>
                            Svako dete je jedinstveno i ima svoj individualni tempo rasta i razvoja.<br/>
                            Za razvoj i normalnu funkciju stvaranja glasa i govora neophodni su uredno razvijeni organi sluha, organi za artikulaciju i uredan intelektualni razvoj.<br/>
                            Razvoj govora najintenzivniji je u ranoj dečjoj dobi, a nastavlja se razvijati kroz celi život.<br/>
                            Upravo iz tog razloga važno je poznavati uredan obrazac govorno-jezičkog razvoja kako bi na vreme mogli prepoznati teškoće i pravovremeno potražiti stručnu pomoć.<br/>
                        </p>
                        <p>
                            U navedenim normama opisan je uredan govorno-jezički razvoj svakog deteta.<br/>
                            Ukoliko primetite da Vaše dete nema uredan obrazac razvoja ili kasni u usvajanju nekih od navedenih veština unutar kategorije detetovog uzrasta, potražite stručnu pomoć logopeda.
                        </p>
                        <span className="button">razvojna norma</span>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
)

export default SeekForHelp;