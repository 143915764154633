import React from 'react'
import { NavLink } from 'react-router-dom'
// import { Container } from 'react-bootstrap'


const NavMenu = () => (
    <nav className="main_nav_menu">
        <ul>
            <li>
                <NavLink to="/" exact={true}>početna</NavLink>
            </li>
            {/* <li>
                <NavLink to="/o-nama">o nama</NavLink> */}
                {/* <div className="dropdown_menu narrow">
                    <ul>
                        <li>
                            <a href="/">
                                Individualni logopedski tretmani
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                Reedukacija psihomotorike
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                Rana stimulacija senzo-motornog razvoja
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                Psihološka testiranja, terapije i savetovanja
                            </a>
                        </li>
                    </ul>
                </div> */}
            {/* </li> */}
            {/* <li className="wide_dropdown">
                <NavLink to="/nas-rad">naš rad</NavLink> */}
                {/* <Container className="dropdown_menu wide">
                    <ul className="clearfix">
                        <li className="dropdown_menu_column">
                            <h5>
                                <a href="/">
                                    Tretmani
                                </a>
                            </h5>
                            <ul>
                                <li>
                                    <a href="/">
                                        Individualni logopedski tretmani
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Reedukacija psihomotorike
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Rana stimulacija senzo-motornog razvoja
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Psihološka testiranja, terapije i savetovanja
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown_menu_column">
                            <h5>
                                <a href="/">
                                    Govorno jezički i razvojni poremećaji
                                </a>
                            </h5>
                            <ul>
                                <li>
                                    <a href="/">
                                        Individualni logopedski tretmani
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Reedukacija psihomotorike
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Rana stimulacija senzo-motornog razvoja
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Psihološka testiranja, terapije i savetovanja
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown_menu_column">
                            <h5>
                                <a href="/">
                                    Poslednje objave
                                </a>
                            </h5>
                            <ul>
                                <li>
                                    <a href="/">
                                        Individualni logopedski tretmani
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Reedukacija psihomotorike
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Rana stimulacija senzo-motornog razvoja
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Psihološka testiranja, terapije i savetovanja
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown_menu_column">
                            <h5>
                                <a href="/">
                                    Pitanja i odgovori
                                </a>
                            </h5>
                            <ul>
                                <li>
                                    <a href="/">
                                        Individualni logopedski tretmani
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Reedukacija psihomotorike
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Rana stimulacija senzo-motornog razvoja
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Psihološka testiranja, terapije i savetovanja
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Container> */}
            {/* </li> */}
            <li>
                <NavLink to="/kontakt">kontakt</NavLink>
            </li>
        </ul>       
    </nav>
)

export default NavMenu