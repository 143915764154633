import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../scss/footer.scss'

const Footer = () => (
    <footer>
        <div className="footer_top">
            <div className="container row">
                <div className="col-md-4 footer_top_col">
                    <h4 className="footer_top_title">Adresa</h4>
                    <div className="footer_top_text">
                        <div>
                            <p>Bulevar Mihajla Pupina 10/ž</p>
                            <p>11070 Novi Beograd</p>
                        </div>
                        <div>
                            <p>
                                <FontAwesomeIcon className="font_awesome contact_icons" icon="mobile-alt"/>
                                <a href="tel:+381691485558">0691485558</a>
                            </p>
                            <p>
                                <FontAwesomeIcon className="font_awesome contact_icons" icon="envelope"/>
                                <a href="mailto:milogosnbgd@gmail.com">milogosnbgd@gmail.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 footer_top_col">
                    <h4 className="footer_top_title">Naše usluge</h4>
                    <div className="footer_top_text">
                        <ul>
                            <li>Individualni logopedski tretmani</li>
                            <li>Reedukacija psihomotorike</li>
                            <li>Rana stimulacija senzo-motornog razvoja</li>
                            <li>Psihološka testiranja, terapije i savetovanja</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-4 footer_top_col">
                    <h4 className="footer_top_title">Kontakt</h4>
                    <div className="footer_top_text">
                        <div className="social_icons_holder">
                            <a href="https://www.facebook.com/logopedbeograd.rs" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'facebook']}/>
                            </a>
                            <a href="https://www.instagram.com/logopedbeograd.rs" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'instagram']}/>
                            </a>
                            <a href="viber://chat/?number=%2B381691485558">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'viber']}/>
                            </a>
                            <a href="https://wa.me/+381691485558">
                                <FontAwesomeIcon className="font_awesome" icon={['fab', 'whatsapp']}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer_bottom">
            <p> Copyright &copy; 2020 MiLogos</p>
        </div>

    </footer>
)

export default Footer