import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const ContactForm = () => {
    return (
        <div className="contact_form_wrapper">
            <div className="contact_form_title_holder">
                <h2>Pišite nam</h2>
            </div>
            <Container>
                <Row>
                    <Col xl="2"></Col>
                    <Col xl="8" className="contact_form_holder">
                        <form className="contact_form">
                            <Row>
                                <Col md="6">
                                    <input type="text" placeholder="Ime" />
                                </Col>
                                <Col md="6">
                                    <input type="text" placeholder="Prezime" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <input type="text" placeholder="E-mail" />
                                </Col>
                                <Col md="6">
                                    <input type="text" placeholder="Telefon" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <textarea placeholder="Poruka..." style={{resize: "none"}} />
                                    <div>
                                        <input type="submit" value="pošaljite" disabled="disabled"/>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                    <Col xl="2"></Col>
                </Row>
            </Container>
        </div>
    )

}

export default ContactForm