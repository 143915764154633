import React from 'react'

import TitleArea from '../components/TitleArea'

import titleBackgroundImage from '../scss/img/about-title.jpg'

const AboutUsPage = () => (
    <>
        <TitleArea title="O Nama" bckgImage={titleBackgroundImage}/>
    </>
)

export default AboutUsPage