import React from 'react'

import '../scss/title_with_background.scss'

const TitleWithBackground = ({titleText, titleBckImg, alignment}) => {
    const titleStyle = {
        backgroundImage: `url(${titleBckImg})`
    }

    const titleClassNames = ['title_with_background'];

    if(alignment) {
        titleClassNames.push(alignment);
    }

    return (
        <h2 
            className = {titleClassNames.join(' ')}
            style = {titleStyle}
        >
            {titleText}
        </h2>
    )
}

export default TitleWithBackground