import React from 'react'

import OurServices from '../components/OurServices'
import SeekForHelp from '../components/SeekForHelp'
import SliderHome from '../components/SliderHome'
import SpeechTherapyInstruments from '../components/SpeechTherapyInstruments'
import GallerySlider from '../components/GallerySlider'

const HomePage = () => (
    <main>
        <SliderHome/>
        <OurServices></OurServices>
        <SeekForHelp/>
        <SpeechTherapyInstruments/>
        <GallerySlider/>
    </main>
)

export default HomePage