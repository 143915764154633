import React from 'react'
import { Row, Col } from 'react-bootstrap'

import '../scss/process.scss'

import processImg1 from '../scss/img/behringer.jpeg'
import processImg2 from '../scss/img/logopedske-sonde.png'
import processImg3 from '../scss/img/talk_tools_dete.jpeg'

const Process = () => (
    <Row className="process_holder">
        <Col lg="4" className="process_item_holder">
            <div className="process_item">
                <img className="process_item_image" src={processImg1} alt="process-img-1"/>
                <div className="process_item_text_holder">
                    <h4>Behringer aparat</h4>
                    {/* <p>Praesent eu tincidunt purus, sed vallis sem</p> */}
                </div>
            </div>
        </Col>
        <Col lg="4" className="process_item_holder">
            <div className="process_item">
                <img className="process_item_image" src={processImg2} alt="process-img-2"/>
                <div className="process_item_text_holder">
                    <h4>Logopedske sonde</h4>
                    {/* <p>Praesent eu tincidunt purus, sed vallis sem</p> */}
                </div>
            </div>
        </Col>
        <Col lg="4" className="process_item_holder">
            <div className="process_item">
                <img className="process_item_image" src={processImg3} alt="process-img-3"/>
                <div className="process_item_text_holder">
                    <h4>Talk Tools</h4>
                    {/* <p>Praesent eu tincidunt purus, sed vallis sem</p> */}
                </div>
            </div>
        </Col>
    </Row>
)

export default Process