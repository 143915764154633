import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import HomePage from '../pages/HomePage'
import AboutUsPage from '../pages/AboutUsPage'
import ContactPage from '../pages/ContactPage'
import OurWorkPage from '../pages/OurWorkPage'

const AppRouter = () => (
    <BrowserRouter>
      <>
        <Header />
        <Switch>
          <Route path="/" component={HomePage} exact={true} />
          <Route path="/o-nama" component={AboutUsPage} />
          <Route path="/nas-rad" component={OurWorkPage} />
          <Route path="/kontakt" component={ContactPage} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>
        <Footer />
      </>
    </BrowserRouter>
  );
  
  export default AppRouter;