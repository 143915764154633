import React from 'react'

import '../scss/title_area.scss';

const TitleArea = (props) => {
    const titleAreaStyle = {
        backgroundImage: `url("${props.bckgImage}")`
    }
    return (
        <div className="title_area" style={titleAreaStyle}>
            <h1>{props.title}</h1>
        </div>
    );
}

export default TitleArea