import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'
import { Row, Col } from 'react-bootstrap'

import 'swiper/swiper.scss'
import '../scss/slider_home_page.scss'

import slide1MainImage from '../scss/img/slider/slide1/main-image.png'
import slide1CookieImage from '../scss/img/slider/slide1/cookie.png'
import slide1DotsImage from '../scss/img/slider/slide1/dots.png'
import slide1GreenImage from '../scss/img/slider/slide1/green-background.png'
import slide1XxxImage from '../scss/img/slider/slide1/xxx.png'
import slide1SquareImage from '../scss/img/slider/slide1/square.png'
import slide1CircleImage from '../scss/img/slider/slide1/circle.png'
import slide1AlphaImage from '../scss/img/slider/slide1/alpha.png'



// import sliderImg2 from '../scss/img/slider/slider-img-2.png'
// import sliderImg3 from '../scss/img/slider/slider-img-3.png'

SwiperCore.use([EffectFade])

const SliderHome = () => (
    <Swiper className="slider_home_page" spaceBetween={50} effect="fade" slidesPerView={1} pagination={{ clickable: true }}>
        <SwiperSlide className="slide slide_one">
        {
            ({isActive}) => 
            (
                <>
                    <Row className="slide_holder align-items-center">
                        <Col lg="6">
                            <div className="slide_column_inner">
                                <div className="slide_main_image_holder">
                                    <img className="slide_main_image" src={slide1MainImage} alt="slika sa slajda"/>
                                    <img className="slide_element slide_image_cookie" src={slide1CookieImage} alt="slika sa slajda"/>
                                    <img className="slide_element slide_image_dots" src={slide1DotsImage} alt="slika sa slajda"/>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="slide_column_inner">
                                <div className="slide_text_holder">
                                    <h1 className="slide_text_title">MiLogos<br/> logopedski centar</h1>
                                    {/* <h2 className="slide_text_title"></h2> */}
                                    <h5 className="slide_text_subtitle">Potražite savete naših stručnjaka, jer rana dijagnostika i adekvatan tretman omogućavaju da se teškoće minimalizuju i eventualno spreče sekundarni poremećaji</h5>
                                    <span className="slide_button_link button">Saznajte više</span>
                                </div>
                                <img className="slide_element slide_image_square" src={slide1SquareImage} alt="slika sa slajda"/>
                            </div>
                        </Col>
                    </Row>
                    <img className="slide_element slide_image_green" src={slide1GreenImage} alt="slika sa slajda"/>
                    <img className="slide_element slide_image_xxx" src={slide1XxxImage} alt="slika sa slajda"/>
                    <img className="slide_element slide_image_circle" src={slide1CircleImage} alt="slika sa slajda"/>
                    <img className="slide_element slide_image_alpha" src={slide1AlphaImage} alt="slika sa slajda"/>
                </>
            )
        }
        </SwiperSlide>
        {/* <SwiperSlide className="slide_two">
            <div></div>
        </SwiperSlide>
        <SwiperSlide className="slide_three">
            <div></div>
        </SwiperSlide> */}
    </Swiper>
)

export default SliderHome