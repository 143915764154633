import React from 'react'
import { Container } from 'react-bootstrap'
import TitleWithBackground from './TitleWithBackground'

import '../scss/speech_therapy_instruments.scss'
import STIBckImage from '../scss/img/title-background-sti.png'
import Process from './Process'

const SpeechTherapyInstruments = () => (
    <div className="sti_holder">
        <Container>
            <div className="sti_title_subtitle_holder">
                <TitleWithBackground
                    titleText="Logopedski instrumenti"
                    titleBckImg={STIBckImage}
                    alignment="center"/>
                <h5 className="sti_subtitle subtitle">
                    {/* Svaka sonda namenjena je korekciji izgovora jednog ili nekoliko određenih glasova. Sonde se aplikuju prema konkretnim i striktnim uputstvima */}
                </h5>
            </div>
            <Process/>
        </Container>
    </div>
)

export default SpeechTherapyInstruments