import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from 'react-bootstrap'

import NavMenu from './NavMenu'
import MobileNavMenu from './MobileNavMenu'
import logo from '../scss/img/logo.png'

import '../scss/header.scss'

const HeaderTop = () => (
    <div className="header_top clearfix">
        <Container className="header_top_inner clearfix">
            <div className="header_top_elements_holder left">
                <p>
                    <FontAwesomeIcon className="font_awesome contact_icons" icon="map-marker-alt"/>
                    Bulevar Mihajla Pupina 10/Ž 11070 Novi Beograd
                </p>
            </div>
            <div className="header_top_elements_holder right">
                <p>
                    <FontAwesomeIcon className="font_awesome contact_icons" icon="envelope"/>
                    <a href="mailto:milogosnbgd@gmail.com">milogosnbgd@gmail.com</a>
                </p>
                <p>
                    <FontAwesomeIcon className="font_awesome contact_icons" icon="mobile-alt"/>
                    <a href="tel:+381691485558">0691485558</a>
                </p>
            </div>
        </Container>
    </div>
);

const HeaderBottom = () => (
    <Container className="header_bottom">
        <MobileNavMenu/>
        <div className="logo">
            <NavLink to="/">
                <img src={logo} alt="logo"/>
            </NavLink>
        </div>
        <NavMenu />
    </Container>
);

const Header = () => (
    <header>
        <HeaderTop />
        <HeaderBottom />
    </header>
);

export default Header
