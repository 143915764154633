import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

import '../scss/contact_page.scss'


const MyGoogleMapComponent = (props) => (
    <Map
        google={props.google}
        zoom={16}
        initialCenter={{ lat: 44.82292906449423, lng: 20.421398851599502}}
        >
            <Marker position={{ lat: 44.82292906449423, lng: 20.421398851599502}} />
    </Map>
)

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCCegGWy9SINImpA8v-fbeQqvybtzn-6dU'
  })(MyGoogleMapComponent);
